<template>
  <div class="content block-el">
    <template v-if="fetched">
      <PageHeader :title="$t('attach_plan')" />
      <div class="page-content container-fluid">
        <form @submit.prevent="attach">
          <div class="row">
            <div class="col-12 col-sm-6">
              <div class="card">
                <div class="card-header">
                  {{ $t('generic-str.menu.general') }}
                </div>
                <div class="card-body">
                  <div class="form-group">
                    <label>
                      {{ $tc('app.plan') }}
                    </label>
                    <select
                      class="form-control"
                      v-model="form.plan_id"
                      @change="changePlan"
                    >
                      <option
                        v-for="plan in plans"
                        :key="plan.id"
                        :value="plan.id"
                      >
                        {{ plan.name }} ({{ plan.service }})
                      </option>
                    </select>
                  </div>
                </div>
              </div>
            </div>
            <div v-if="service" class="col-12 col-sm-6">
              <div class="card">
                <div class="card-header">
                  <!-- <template v-if="service == 'omni'">Omni Business</template> -->
                  <template v-if="service == 'omni'">Chat</template>
                  <template v-else-if="service == 'whatsapp'"
                    >Whatsapp API</template
                  >
                  <template v-else-if="service == 'rcs'">RCS Google</template>
                  <template v-else-if="service == 'sms'">SMS</template>
                  <template v-else-if="service == 'email'">E-Mail</template>
                </div>
                <div class="card-body">
                  <div v-if="selectedPlan.type == 'subscription' || !selectedPlan.type" class="form-group">
                    <label>
                      {{ $tc('app.fee') }}
                    </label>
                    <input
                      type="text"
                      class="form-control"
                      v-model="form.pricing.fee"
                      v-money="money"
                      required
                    />
                  </div>
                  <div v-if="selectedPlan.type == 'subscription' || !selectedPlan.type" class="form-group">
                    <label>
                      {{ $tc('app.message', 2) }}
                    </label>
                    <div class="input-group">
                      <input
                        class="form-control"
                        v-model="form.features.messages"
                        required
                      />
                      <div class="input-group-append">
                        <span class="input-group-text" id="basic-addon2"
                          >Mensagens</span
                        >
                      </div>
                    </div>
                  </div>
                  <div class="form-group">
                    <label>
                      {{ $tc('app.message_price') }}
                    </label>
                    <input
                      type="text"
                      class="form-control"
                      v-model="form.pricing.message"
                      v-money="money"
                      required
                    />
                  </div>
                  <div class="form-group">
                    <label>
                      {{ $tc('app.session_price') }}
                    </label>
                    <input
                      type="text"
                      class="form-control"
                      v-model="form.pricing.session"
                      v-money="money"
                      required
                    />
                  </div>
                  <template v-if="service == 'omni'">
                    <div class="form-group">
                      <label>
                        {{ $tc('app.agent', 2) }}
                      </label>
                      <div class="input-group">
                        <input
                          class="form-control"
                          v-model="form.features.agent"
                          required
                        />
                        <div class="input-group-append">
                          <span class="input-group-text" id="basic-addon2">{{
                            $tc('app.agent', 2)
                          }}</span>
                        </div>
                      </div>
                    </div>
                    <div class="form-group">
                      <label>
                        {{ $tc('app.agent_price') }}
                      </label>
                      <input
                        type="text"
                        class="form-control"
                        v-model="form.pricing.agent"
                        v-money="money"
                        required
                      />
                    </div>
                    <div class="form-group">
                      <label>
                        {{ $tc('storage') }}
                      </label>
                      <div class="input-group">
                        <input
                          class="form-control"
                          v-model="form.features.storage"
                          required
                        />
                        <div class="input-group-append">
                          <span class="input-group-text" id="basic-addon2"
                            >MB</span
                          >
                        </div>
                      </div>
                    </div>
                    <div class="form-group">
                      <label>
                        {{ $tc('app.storage_price') }}
                      </label>
                      <input
                        type="text"
                        class="form-control"
                        v-model="form.pricing.storage"
                        v-money="money"
                        required
                      />
                    </div>
                  </template>
                  <div
                    v-if="
                      ['sms', 'rcs', 'email'].includes(service) &&
                      selectedPlan.type == 'subscription' || !selectedPlan.type
                    "
                    class="form-group"
                  >
                    <label>
                      {{ $tc('app.limit', 2) }}
                    </label>
                    <div class="input-group">
                      <input
                        class="form-control"
                        v-model="form.features.limit"
                        required
                      />
                      <div class="input-group-append">
                        <span class="input-group-text" id="basic-addon2"
                          >Mensagens</span
                        >
                      </div>
                    </div>
                  </div>
                </div>
                <div class="card-footer bg-light">
                  <custom-button :loading="attaching">{{
                    $t('app.save')
                  }}</custom-button>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </template>
    <!-- <div v-else class="relative qt-block-ui" style="padding: 120px" /> -->
    <div v-else class="static qt-block-ui" style="padding: 120px" />
  </div>
</template>

<script>
// @ is an alias to /src
import Tab from '@/directives/Tab';
import PageHeader from '@/components/PageHeader.vue';
import CustomButton from '@/components/CustomButton';
import { mask } from 'vue-the-mask';

export default {
  name: 'AttachPlan',
  components: {
    PageHeader,
    CustomButton,
  },
  directives: {
    Tab,
    mask,
  },
  data() {
    return {
      fetched: false,
      attaching: false,
      money: {
        decimal: ',',
        thousands: '.',
        prefix: 'R$ ',
        precision: 4,
        masked: true,
      },
      plans: [],
      selectedPlan: {},
      services: {
        // omni: 'Omni Business',
        omni: 'Chat',
        whatsapp: 'Whatsapp API',
        rcs: 'Google RCS',
        sms: 'SMS',
        email: 'E-Mail',
      },
      type: '',
      customer: {},
      form: {
        plan: {},
        pricing: {
          model: 'fixed',
        },
        features: {},
      },
      smsPrices: {
        claro: 0.1,
        vivo: 0.1,
        tim: 0.1,
        oi: 0.1,
        other: 0.1,
      },
      pages: 1,
    };
  },
  created() {
    this.fetchPlans();
  },
  computed: {
    service() {
      let service = '';
      this.plans.forEach((element) => {
        if (this.form.plan_id === element.id) service = element.service;
      });
      return service;
    },
  },
  methods: {
    fetchPlans() {
      this.$store.dispatch('fetchAdminPlans').then((response) => {
        this.fetched = true;
        this.plans = response;
      });
    },
    changePlan(plan) {
      this.plans.forEach((element) => {
        if (this.form.plan_id === element.id) this.selectedPlan = element;
      });
    },
    attach() {
      this.attaching = true;
      this.$store
        .dispatch('attachPlan', {
          customerId: this.$route.params.id,
          input: this.form,
        })
        .then((response) => {
          this.$toast.show({
            title: this.$t('generic-str.success'),
            content: this.$t('adm.customer.assign.associated-plan'),
            type: 'success',
          });
          this.$router.push('../');
        })
        .catch((ex) => {
          ex.response.data.errors.forEach((error) => {
            this.$toast.show({
              title: `${this.$t('sms.infos.filters.status.lbl-error')} ${
                error.code
              }`,
              content: error.detail,
              type: 'danger',
            });
          });
        })
        .finally(() => {
          this.attaching = false;
        });
    },
  },
};
</script>
<style lang="scss" scoped>
</style>
